import { AltLayout } from "layouts/AltLayout";
import { theme } from "@/GlobalStyles";
import AlternativeHeroBanner from "@/common/alternative-hero-banner";
import styled from "@emotion/styled";
import ArrowButton from "@/common/arrow-button";
import { ReactComponent as CalendarIcon } from "../../svgs/calendar.svg";
import { StaticImage } from "gatsby-plugin-image";
import { Title } from "styles/typography";
import thumbnail from "../../images/upcoming-events-video-thumbnail.jpg";
import thumbnail2 from "../../images/upcoming-events-video-2-thumbnail.jpg";
import video from "../../../assets/events.mp4";
import video2 from "../../../assets/events-2.mp4";

const Event = styled.div<{ imgLast?: boolean }>`
  margin-bottom: 64px;
  padding-bottom: 64px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tomatoRed};

  > div:last-of-type {
    max-width: 528px;
    margin-top: 32px;
    padding: 0 16px;

    > h2 {
      color: ${({ theme }) => theme.colors.tomatoRed};
      margin: 32px 0;

      :first-of-type {
        font-weight: 400;
        margin: 0;
      }
    }

    > p,
    > a {
      color: ${({ theme }) => theme.colors.tomatoRed};
    }
  }

  + h2 {
    color: ${({ theme }) => theme.colors.tomatoRed};
    margin: -24px 0 48px;
    font-size: 2rem;
  }

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;

    > div:last-of-type {
      margin-top: 0;
      width: 528px;
      padding: ${({ imgLast }) => (imgLast ? "0 0 0 16px" : "0 16px 0 0")};
    }

    > .gatsby-image-wrapper {
      order: ${({ imgLast }) => (imgLast ? 1 : "unset")};
      margin: ${({ imgLast }) => (imgLast ? "0 0 0 32px" : "0 32px 0 0")};
    }
  }

  @media (min-width: 1280px) {
    > div:last-of-type {
      padding: 0;
    }

    + h2 {
      color: ${({ theme }) => theme.colors.tomatoRed};
      margin: 0 0 96px;
    }
  }
`;

const Video1 = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  object-fit: cover !important;
  width: 100%;
  height: 100%;
`;

const Video2 = styled.video`
  max-width: 1280px;
  max-height: 720px;
  width: calc(100vw - 32px);
  border-radius: 32px;
  overflow: hidden;
  height: 100%;
  margin: 16px 16px 64px;
  aspect-ratio: 1280 / 720;
`;

const UpcomingEventsPage = () => {
  return (
    <div>
      <AltLayout>
        <AlternativeHeroBanner
          IntroHeader="Upcoming Events with Pearl"
          NavigateTo="Upcoming Events"
          NavigateFrom="Home"
          PageName="Upcoming Events"
          BgHero="video"
          introHeadColor={theme.colors.tomatoRed}
        >
          <Video1
            poster={thumbnail}
            controls={false}
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={video} type="video/mp4" />
          </Video1>
        </AlternativeHeroBanner>

        <Video2 poster={thumbnail2} controls={true} playsInline>
          <source src={video2} type="video/mp4" />
        </Video2>

        <Event>
          <StaticImage
            src="../../images/upcoming-events-3.jpg"
            alt="Charlie Daniels Park"
            width={528}
            height={685}
          />
          <div>
            <CalendarIcon />
            <Title>July 15th, 2023</Title>
            <Title>Charlie Daniels Park</Title>
            <p>Charlie Daniels Park from 4PM Onward</p>
            <a
              href="https://www.mtjuliet-tn.gov/169/Events"
              rel="noopener noreferrer"
              target="_blank"
            >
              <ArrowButton
                title="Learn More Here"
                titleColor={theme.colors.tomatoRed}
                border={`2px solid ${theme.colors.tomatoRed}`}
              />
            </a>
          </div>
        </Event>

        <Event imgLast>
          <StaticImage
            src="../../images/upcoming-events-4.jpg"
            alt="Back to School Party"
            width={528}
            height={528}
          />
          <div>
            <CalendarIcon />
            <Title>July 29th, 2023</Title>
            <Title>Back to School Party</Title>
            <p>At Pearl Pediatric Dentistry & Orthodontics</p>
          </div>
        </Event>

        <h2>Older Events</h2>
        <Event>
          <StaticImage
            src="../../images/upcoming-events-2.jpg"
            alt="run the cedars"
            width={528}
            height={386}
          />
          <div>
            <CalendarIcon />
            <Title>April 8th, 2023</Title>
            <Title>Run the Cedars 5K/15K</Title>
            <a
              href="https://runsignup.com/Race/Events/TN/MountJuliet/WilsonCounty2021FCA5K"
              rel="noopener noreferrer"
              target="_blank"
            >
              <ArrowButton
                title="Sign up here"
                titleColor={theme.colors.tomatoRed}
                border={`2px solid ${theme.colors.tomatoRed}`}
              />
            </a>
          </div>
        </Event>

        <Event imgLast>
          <StaticImage
            src="../../images/upcoming-events-1.jpg"
            alt="easter in the park"
            width={528}
            height={288}
          />
          <div>
            <CalendarIcon />
            <Title>April 1st, 2023</Title>
            <Title>Easter Egg Hunt</Title>
            <p>Charlie Daniels Park from 11:00 am to 2:00 pm</p>
            <a
              href="https://www.mtjuliet-tn.gov/169/Events"
              rel="noopener noreferrer"
              target="_blank"
            >
              <ArrowButton
                title="Learn More Here"
                titleColor={theme.colors.tomatoRed}
                border={`2px solid ${theme.colors.tomatoRed}`}
              />
            </a>
          </div>
        </Event>
      </AltLayout>
    </div>
  );
};

export default UpcomingEventsPage;
